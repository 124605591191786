import React from 'react';
import Box from '@webAtoms/Box/Box';
import UpNext from '@webMolecules/UpNext/UpNext';
import * as Route from '@routes';
import Intro, { IntroProps } from './Sections/Intro';
import Blogs, { BlogsProps } from './Sections/Blogs';

export interface JournalProps {
  intro: IntroProps;
  blogs: BlogsProps;
}

const Journal: React.FC<JournalProps> = ({ intro, blogs }) => {
  return (
    <>
      <Box>
        <Intro {...intro} />
        <Blogs {...blogs} />
      </Box>

      <UpNext
        links={[
          { label: 'Our approach', href: Route.ourApproach() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </>
  );
};

export default Journal;
