import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as Route from '@routes';
import * as styles from './intro.module.scss';
import { ucfirst } from 'essential-ts-utils';

export interface IntroProps {
  title: string;
  body: string;
  category?: string;
}

const Journal: React.FC<IntroProps> = ({ title, body, category }) => {
  return (
    <Container>
      <Box className={styles.journalHeading}>
        <Box className={styles.journalHeadingColumn}>
          <h1>
            <a href={Route.journal()}>{title}</a>
            {category && (
              <span>
                :<br /> {ucfirst(category.replace(/-/g, ' '))}
              </span>
            )}
          </h1>
        </Box>
        <Box className={styles.journalHeadingColumn}>
          <Box className={styles.journalHeadingTagCloud} htmlContent={body} />
        </Box>
      </Box>
    </Container>
  );
};

export default Journal;
