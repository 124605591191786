import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Blog from '@localTypes/Blog';
import CardJournal from '@webMolecules/Card/CardJournal';
import * as styles from './blogs.module.scss';

export interface BlogsProps {
  blogs: Blog[];
}

const Blogs: React.FC<BlogsProps> = ({ blogs }) => {
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);

  const { search } =
    typeof window !== 'undefined' ? window.location : { search: '' };
  const searchParams = new URLSearchParams(search);
  const category = searchParams.get('category') || undefined;

  const currentDate = new Date();
  const filteredBlogs = isClient
    ? blogs.filter(
        blog =>
          blog.date.getTime() < currentDate.getTime() &&
          (category === undefined || blog.tags.find(a => a.key === category))
      )
    : blogs;

  return (
    <Container>
      <Box className={styles.journalItems}>
        {filteredBlogs.map(blog => (
          <CardJournal key={blog.slug} blog={blog} />
        ))}
      </Box>
    </Container>
  );
};

export default Blogs;
