import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageJournalQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import Journal from '@webPages/Journal/Journal';
import decodeBlogs from '@decoders/Blogs';

const PageJournal: React.FC<PageProps<PageJournalQuery>> = ({
  data,
  location,
}) => {
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);

  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const category = searchParams.get('category') || undefined;

  const decodedBlogs = decodeBlogs(data?.blogs?.edges || []);

  const title = data?.journal?.frontmatter?.title || '';

  const seoTitle = data?.journal?.frontmatter?.seoTitle || title || undefined;
  const seoDescription =
    data?.journal?.frontmatter?.seoDescription ||
    data?.journal?.excerpt ||
    undefined;
  const image =
    (data?.blogs?.edges || [])[0]?.node?.frontmatter?.thumb?.publicURL ||
    undefined;
  const props = {
    intro: {
      title,
      body: data?.journal?.html || '',
      category,
    },
    blogs: {
      blogs: decodedBlogs,
    },
  };

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <Journal {...props} />
    </Base>
  );
};

export default PageJournal;

export const query = graphql`
  query PageJournal {
    journal: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "journal" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
      }
    }

    blogs: allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            video
            # embed
            tags
            thumb {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  height: 340
                  width: 392
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, JPG]
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`;
